import utils from 'utils'

export default {
  'css': ``,

  'exports': {
    onBeforeMount() {
    },

    onMounted() {
        this.update()
    },

    // onchange handler for reportinghours selectall checkbox
    selectAll(e) {
        if (this.props.reportinghours == null) {
            return
        }
        for (var i=0; i < this.props.reportinghours.length; i++) {
            this.props.reportinghours[i].IsApproved = e.target.checked
        }
        this.update()
    },

    // onchange handler for reportinghours approval checkbox
    approveReporting(e) {
        if (this.props.reportinghours == null) {
            return
        }
        for (var i=0; i < this.props.reportinghours.length; i++) {
            if (this.props.reportinghours[i].Id == e.target.value) {
                this.props.reportinghours[i].IsApproved = e.target.checked
                break
            }
        }
        this.update()
    },

    // Submit approvals to backend
    submit() {
        let employeeid = this.props.employeeid
        let callback = this.props.callback
        let errortag = document.getElementById('approvehours-error')
        let successtag = document.getElementById('approvehours-status')
        let reportinghours = this.props.reportinghours
        return function(e) { 
            errortag.style.display = "none"
            e.preventDefault()
            fetch('/api/reporting', {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reportinghours)
            })
            .then( response => {
                // if response is ok, then call the callback function
                // otherwise an errormessage is a text in the body
                // so display it in the errortag
                if (response.ok) { 
                    successtag.style.display = "block"
                    successtag.innerHTML = "Ændringerne er gemt"
                   return null
                } else {
                    return response.text()
                }
            })
            .then( text => {
                if (text) {
                    throw Error(text)
                }
            })
            .catch( error =>  {
                errortag.style.display = "block"
                errortag.innerHTML = error
                console.error("reporthours.submit:", error)
            })
        }
    },

    // Handle show/hide of error/succes alerts (work in progress)
    alert(tag, text, type) {
        let errortag = document.getElementById('approvehours-error')
        setTimeout(function() {
            errortag.addClass('show');
        },0);
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="card"><div class="card-header">Timer</div><div class="card-body"><form class="form-inline"><table class="table table-sm"><thead><th><input expr57="expr57" type="checkbox"/></th><th>Dato</th><th>Formål</th><th>Timer</th></thead><tbody><tr expr58="expr58"></tr></tbody></table><button expr63="expr63" class="btn btn-success" type="submit">Godkend valgte</button></form><div id="approvehours-error" class="alert alert-danger hide fade" role="alert"></div><div id="approvehours-status" class="alert alert-success hide fade" role="alert"></div></div></div>',
      [{
        'redundantAttribute': 'expr57',
        'selector': '[expr57]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onchange',

          'evaluate': function(scope) {
            return scope.selectAll;
          }
        }]
      }, {
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(
          '<td><input expr59="expr59" type="checkbox"/></td><td expr60="expr60"> </td><td expr61="expr61"> </td><td expr62="expr62"> </td>',
          [{
            'redundantAttribute': 'expr59',
            'selector': '[expr59]',

            'expressions': [{
              'type': expressionTypes.VALUE,

              'evaluate': function(scope) {
                return scope.report.Id;
              }
            }, {
              'type': expressionTypes.ATTRIBUTE,
              'name': 'checked',

              'evaluate': function(scope) {
                return scope.report.IsApproved;
              }
            }, {
              'type': expressionTypes.EVENT,
              'name': 'onchange',

              'evaluate': function(scope) {
                return scope.approveReporting;
              }
            }]
          }, {
            'redundantAttribute': 'expr60',
            'selector': '[expr60]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.utils.formatDate(scope.report.Date);
              }
            }]
          }, {
            'redundantAttribute': 'expr61',
            'selector': '[expr61]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.report.Description;
              }
            }]
          }, {
            'redundantAttribute': 'expr62',
            'selector': '[expr62]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.report.Amount.toFixed(1);
              }
            }]
          }]
        ),

        'redundantAttribute': 'expr58',
        'selector': '[expr58]',
        'itemName': 'report',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.props.reportinghours;
        }
      }, {
        'redundantAttribute': 'expr63',
        'selector': '[expr63]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.submit();
          }
        }]
      }]
    );
  },

  'name': 'approvehours'
};