//import moment from '../../node_modules/moment/dist/moment.js'
import utils from 'utils'

export default {
  'css': `approvefiles #approvefiles-error,[is="approvefiles"] #approvefiles-error,approvefiles #approvefiles-status,[is="approvefiles"] #approvefiles-status{ display:none; }`,

  'exports': {
    onBeforeMount() {
    },

    onMounted() {
        this.update()
    },

    // formatDate(date) {
    //     return moment(date).format('L')
    // },

    // // Format number nicely with thousand separators
    // numberWithSeparator(number) {
    //     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    // },

    // onchange handler for reportingfiles selectall checkbox
    selectAll(e) {
        if (this.props.reportingfiles == null) {
            return
        }
        for (var i=0; i < this.props.reportingfiles.length; i++) {
            this.props.reportingfiles[i].IsApproved = e.target.checked
        }
        this.update()
    },

    // onchange handler for reportingfiles approval checkbox
    approveReporting(e) {
        if (this.props.reportingfiles == null) {
            return
        }
        for (var i=0; i < this.props.reportingfiles.length; i++) {
            if (this.props.reportingfiles[i].Id == e.target.value) {
                this.props.reportingfiles[i].IsApproved = e.target.checked
                break
            }
        }
        this.update()
    },

    // Submit approvals to backend
    submit() {
        let employeeid = this.props.employeeid
        let callback = this.props.callback
        let errortag = document.getElementById('approvefiles-error')
        let successtag = document.getElementById('approvefiles-status')
        let reportingfiles = this.props.reportingfiles
        return function(e) { 
            errortag.style.display = "none"
            e.preventDefault()
            fetch('/api/reporting', {
                method: "PUT",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reportingfiles)
            })
            .then( response => {
                // if response is ok, then call the callback function
                // otherwise an errormessage is a text in the body
                // so display it in the errortag
                if (response.ok) { 
                    successtag.style.display = "block"
                    successtag.innerHTML = "Ændringerne er gemt"
                   return null
                } else {
                    return response.text()
                }
            })
            .then( text => {
                if (text) {
                    throw Error(text)
                }
            })
            .catch( error =>  {
                errortag.style.display = "block"
                errortag.innerHTML = error
                console.error("reporthours.submit:", error)
            })
        }
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="card"><div class="card-header">Udlæg med bilag</div><div class="card-body"><form class="form-inline"><table class="table table-sm"><thead><th><input expr44="expr44" type="checkbox"/></th><th>Dato</th><th>Beskrivelse</th><th>Timer</th><th>Bilag</th></thead><tbody><tr expr45="expr45"></tr></tbody></table><button expr51="expr51" class="btn btn-success" type="submit">Godkend valgte</button></form><div id="approvefiles-error" class="alert alert-danger" role="alert"></div><div id="approvefiles-status" class="alert alert-success" role="alert"></div></div></div>',
      [{
        'redundantAttribute': 'expr44',
        'selector': '[expr44]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onchange',

          'evaluate': function(scope) {
            return scope.selectAll;
          }
        }]
      }, {
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(
          '<td><input expr46="expr46" type="checkbox"/></td><td expr47="expr47"> </td><td expr48="expr48"> </td><td expr49="expr49"> </td><td><a expr50="expr50"><i class="fa fa-document"></i></a></td>',
          [{
            'redundantAttribute': 'expr46',
            'selector': '[expr46]',

            'expressions': [{
              'type': expressionTypes.VALUE,

              'evaluate': function(scope) {
                return scope.report.Id;
              }
            }, {
              'type': expressionTypes.ATTRIBUTE,
              'name': 'checked',

              'evaluate': function(scope) {
                return scope.item.Approved;
              }
            }, {
              'type': expressionTypes.EVENT,
              'name': 'onchange',

              'evaluate': function(scope) {
                return scope.approveReporting;
              }
            }]
          }, {
            'redundantAttribute': 'expr47',
            'selector': '[expr47]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.utils.formatDate(scope.item.Date);
              }
            }]
          }, {
            'redundantAttribute': 'expr48',
            'selector': '[expr48]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.item.Description;
              }
            }]
          }, {
            'redundantAttribute': 'expr49',
            'selector': '[expr49]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.item.Amount.toFixed(1);
              }
            }]
          }, {
            'redundantAttribute': 'expr50',
            'selector': '[expr50]',

            'expressions': [{
              'type': expressionTypes.ATTRIBUTE,
              'name': 'href',

              'evaluate': function(scope) {
                return ['/document/', scope.item.DocumentUUID].join('');
              }
            }]
          }]
        ),

        'redundantAttribute': 'expr45',
        'selector': '[expr45]',
        'itemName': 'item',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.state.items;
        }
      }, {
        'redundantAttribute': 'expr51',
        'selector': '[expr51]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.save;
          }
        }]
      }]
    );
  },

  'name': 'approvefiles'
};