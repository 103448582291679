import utils from 'utils'
import imageupload from './imageupload.riot'

export default {
  'css': `reportfiles #reportingfiles-error,[is="reportfiles"] #reportingfiles-error{ display:none; } reportfiles .upload-btn-wrapper,[is="reportfiles"] .upload-btn-wrapper{ position: relative; overflow: hidden; display: inline-block; } reportfiles .upload-btn-wrapper input[type=file],[is="reportfiles"] .upload-btn-wrapper input[type=file]{ font-size: 100px; position: absolute; left: 0; top: 0; opacity: 0; }`,

  'exports': {
    onBeforeMount() {
        this.state = {
            user: JSON.parse(sessionStorage.getItem('user')),
            selectedEmployeeID: this.props.employeeid,
            reportingfiles: this.props.reportingfiles
        }
    },

    onMounted()  {
        this.update()
    },

    validateForm(form) {
        form.classList.add('was-validated')
        return form.checkValidity()
    },

    // Save a new files record
    submit() {
        let employeeid = this.props.employeeid
        let callback = this.props.callback
        let errortag = document.getElementById('reportingfiles-error')
        let validateForm = this.validateForm

        var form = document.getElementById('reportfiles-form');
        let dateField = document.getElementById('reportfiles-date')
        let descriptionField = document.getElementById('reportfiles-description')
        let amountField = document.getElementById('reportfiles-amount')
        let fileField = document.getElementById('reportfiles-file')
        return function(e) { 
            errortag.style.display = "none"
            console.log("reportfiles.submit")
            e.preventDefault()
            if (!validateForm(form)) {
                return
            }
            let date = document.getElementById('reportfiles-date').value
            let description = document.getElementById('reportfiles-description').value
            let amount = document.getElementById('reportfiles-amount').value
            fetch('/api/reporting', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "EmployeeID":    employeeid,
                    "Date":          dateField.value,
                    "Description":   descriptionField.value,
                    "Amount":        parseFloat(amountField.value.replace(",", ".")),
                    "ReportingType": 2 // 2=filereport
                })
            })
            .then( response => {
                // if response is ok, then call the callback function
                // otherwise an errormessage is a text in the body
                // so display it in the errortag
                if (response.ok) { 
                    dateField.value = ""
                    descriptionField.value = ""
                    amountField.value = ""
                    fileField.value = ""
                    form.classList.remove('was-validated')
                    callback()
                    return null
                } else {
                    return response.text()
                }
            })
            .then( text => {
                if (text) {
                    throw Error(text)
                }
            })
            .catch((error) =>  {
                errortag.style.display = "block"
                errortag.innerHTML = error
                console.error("reportfiles.submit:", error)
            })
        }
    },

    imageSetup() {
        console.log("imageSetup()")
        return {
            "id":123,
            "hasThumb":false,
            "uploadUrl":"http://upload",
            "imageUrl":"http://image",
            "thumbnailUrl":"http://thumb"
        }
    },

    // formatDate(date) {
    //     return moment(date).format('L')
    // },
    // // Format number nicely with thousand separators
    // numberWithSeparator(x) {
    //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    // },
    imageChanged() {
        console.log("imageChanged")
        return;
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="card"><div class="card-header">Udlæg med bilag</div><div class="card-body"><form id="reportfiles-form" class="needs-validation" novalidate><table class="table table-sm"><thead><tr><th>Dato</th><th>Beskrivelse</th><th>Beløb (kr)</th><th>Bilag</th><th>Godkendt</th><th></th></tr></thead><tbody><tr expr52="expr52"></tr><tr><td><input type="date" class="form-control" id="reportfiles-date" name="date" required/><div class="valid-feedback">Valid.</div><div class="invalid-feedback">Der skal vælges en dato.</div></td><td><input type="text" class="form-control" id="reportfiles-description" name="description" placeholder="Hvad dækker bilaget over" required/><div class="valid-feedback">Valid.</div><div class="invalid-feedback">Der skal indtastes et formål.</div></td><td><input type="text" class="form-control" id="reportfiles-amount" name="amount" placeholder="Indtast et beløb" required/><div class="valid-feedback">Valid.</div><div class="invalid-feedback">Der skal indtastes et beløb.</div></td><td><div class="upload-btn-wrapper"><button class="btn btn-outline-info">Vedhæft bilag</button><input type="file" id="reportfiles-file" name="reportfiles-file" accept="image/*,.pdf" required/></div><div class="valid-feedback">Valid.</div><div class="invalid-feedback">Der skal uploades et bilag.</div></td><td><button expr59="expr59" class="btn btn-outline-info float-left">Tilføj</button></td></tr></tbody></table></form><div id="reportingfiles-error" class="alert alert-danger" role="alert"></div></div></div>',
      [{
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(
          '<td expr53="expr53"> </td><td expr54="expr54"> </td><td expr55="expr55"> </td><td expr56="expr56"> </td><td expr57="expr57"></td><td expr58="expr58"></td><td><i class="far fa-trash-alt" style="font-size:20px;color:red;cursor:pointer"></i></td>',
          [{
            'redundantAttribute': 'expr53',
            'selector': '[expr53]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.utils.formatDate(scope.report.Date);
              }
            }]
          }, {
            'redundantAttribute': 'expr54',
            'selector': '[expr54]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.report.Description;
              }
            }]
          }, {
            'redundantAttribute': 'expr55',
            'selector': '[expr55]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.report.Amount.toFixed(2);
              }
            }]
          }, {
            'redundantAttribute': 'expr56',
            'selector': '[expr56]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.report.DocumentUUID;
              }
            }]
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return scope.report.IsApproved;
            },

            'redundantAttribute': 'expr57',
            'selector': '[expr57]',
            'template': template('<i class="fas fa-check"></i>', [])
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return !scope.report.IsApproved;
            },

            'redundantAttribute': 'expr58',
            'selector': '[expr58]',
            'template': template(null, [])
          }]
        ),

        'redundantAttribute': 'expr52',
        'selector': '[expr52]',
        'itemName': 'report',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.state.reportingfiles;
        }
      }, {
        'redundantAttribute': 'expr59',
        'selector': '[expr59]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.submit();
          }
        }]
      }]
    );
  },

  'name': 'reportfiles'
};