import { route, router, setBase } from '@riotjs/route'

export default {
  'css': `login,[is="login"],login html,[is="login"] html,login body,[is="login"] body{ height: 100%; } login,[is="login"],login body,[is="login"] body{ display: -ms-flexbox; display: flex; -ms-flex-align: center; align-items: center; padding-top: 40px; padding-bottom: 40px; background-color: #f5f5f5; } login .form-signin,[is="login"] .form-signin{ width: 100%; max-width: 330px; padding: 15px; margin: auto; } login .form-signin .checkbox,[is="login"] .form-signin .checkbox{ font-weight: 400; } login .form-signin .form-control,[is="login"] .form-signin .form-control{ position: relative; box-sizing: border-box; height: auto; padding: 10px; font-size: 16px; } login .form-signin .form-control:focus,[is="login"] .form-signin .form-control:focus{ z-index: 2; } login .form-signin input[type="email"],[is="login"] .form-signin input[type="email"]{ margin-bottom: -1px; border-bottom-right-radius: 0; border-bottom-left-radius: 0; } login .form-signin input[type="password"],[is="login"] .form-signin input[type="password"]{ margin-bottom: 10px; border-top-left-radius: 0; border-top-right-radius: 0; } login .bd-placeholder-img,[is="login"] .bd-placeholder-img{ font-size: 1.125rem; text-anchor: middle; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; } @media (min-width: 768px) { login .bd-placeholder-img-lg,[is="login"] .bd-placeholder-img-lg{ font-size: 3.5rem; } }`,

  'exports': {
    login: (e) => {
      e.preventDefault()
      let email = document.getElementById('inputEmail').value
      let password = document.getElementById('inputPassword').value
      fetch('/api/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "email": email, "password": password })
      })
        .then(response => {
          if (response.status != 200) {
            let error = document.getElementById('error')
            error.innerHTML = "Forkert brugernavn eller adgangskode"
            return;
          } else {
            // Go to frontpage
            window.location = "/"
          }
        })
        .catch((error) => {
          console.error("login error: ", error)
        })
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="container"><div class="text-center"><form class="form-signin"><img class="mb-4" src="/images/CERA-logo.png" alt="CERA Logo" width="300"/><h1 class="h3 mb-3 font-weight-normal">Venligst Log ind</h1><label for="inputEmail" class="sr-only">Email</label><input type="email" name="email" id="inputEmail" class="form-control" placeholder="Email" required autofocus oninvalid="this.setCustomValidity(\'Email skal udfyldes\')" oninput="setCustomValidity(\'\')"/><label for="inputPassword" class="sr-only">Adgangskode</label><input type="password" name="password" id="inputPassword" class="form-control" placeholder="Adgangskode" required autocomplete="on" oninvalid="this.setCustomValidity(\'Adgangskode skal udfyldes\')" oninput="setCustomValidity(\'\')"/><div id="error"></div><div class="checkbox mb-3"><label><input type="checkbox" value="remember-me"/> Husk mig\n          </label></div><button expr50="expr50" class="btn btn-lg btn-primary btn-block" type="submit">Log ind</button><p class="mt-5 mb-3 text-muted">&copy; CERA ApS 2020</p></form></div></div>',
      [{
        'redundantAttribute': 'expr50',
        'selector': '[expr50]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.login;
          }
        }]
      }]
    );
  },

  'name': 'login'
};