import { route, router, setBase } from '@riotjs/route'
import * as riot from 'riot'

import selectemployee from '../components/selectemployee.riot'
import selectrole from '../components/selectrole.riot'

export default {
  'css': `user .form-inline .form-group select,[is="user"] .form-inline .form-group select,user .form-inline .form-group label,[is="user"] .form-inline .form-group label{ margin-right: 5px; }`,

  'exports': {
    components: {
      selectemployee,
      selectrole
    },

    onBeforeMount() {
      this.state = {
        user: { ID: this.props.userid, Role: 0, EmployeeID: 0 },
      }
      // If userid == 0 we are creating a new user, so will not try to get one from the backend
      if (this.props.userid != 0) {
        this.getUser(this.props.userid)
      }
    },

    onMounted() {
      this.update()
    },

    validate() {
      var form = document.getElementById('userform')
      var passed = false
      if (form.checkValidity() === false) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        passed = true
      }
      form.classList.add('was-validated')
      return passed
    },

    getUser(id) {
      let url = '/api/users/' + this.props.userid
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignUser(jsonResponse)
          })
        })
        .catch(error => {
          console.log("getUser fetch Error: ", error)
          if (error.status == 401) {
            let errorField = document.getElementById('error')
            errorField.innerHTML = "Der skete en fejl ved hentning af denne bruger: " + error.responseText
            //window.location = "/login"
          }
        })
    },

    assignUser(user) {
      this.state.user = user
      this.update()
    },

    // When an employee is selected, update the users attributes
    selectEmployee(employee) {
      this.state.user.EmployeeID = employee.ID
      this.state.user.Username = employee.Name
      this.state.user.Email = employee.Email
      this.update()
    },

    // Callback for when a role is selected
    selectRole(role) {
      this.state.user.Role = role.ID
      this.update()
    },

    success() {
      // Go to User list
      window.location = "/users"
    },

    submit() {
      let userid = this.props.userid
      let validatefunc = this.validate
      let successfunc = this.success
      let state = this.state
      return function (e) {
        e.preventDefault()
        // Validate form
        if (!validatefunc()) {
          return
        }
        let username = document.getElementById('username').value
        let email = document.getElementById('email').value
        let password = document.getElementById('password').value
        let role = state.user.Role
        let employeeid = state.user.EmployeeID
        let errortag = document.getElementById('error')
        fetch('/api/users/' + userid, {
          method: userid == 0 ? "POST" : "PUT",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "Username": username,
            "Email": email,
            "Password": password,
            "Role": role,
            "EmployeeID": employeeid
          })
        })
          .then(response => {
            if (response.status != 200) {
              console.log("Something went wrong:", response.responseText)
              errortag.innerHTML = response.responseText
              return;
            } else {
              successfunc()
            }
          })
          .catch((error) => {
            errortag.innterHTML = error.responseText
            console.error("user.submit: fetch error: ", error)
          })
      }
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<h3 expr63="expr63"></h3><h3 expr64="expr64"></h3><form id="userform" class="needs-validation" novalidate><selectemployee expr65="expr65" type="bookable"></selectemployee><div class="form-group"><label for="username">Navn</label><input expr66="expr66" id="username" name="username" type="text" class="form-control" autocomplete="cera-off" placeholder="Navn" required/><div class="invalid-feedback">\n        Navn skal udfyldes.\n      </div></div><div class="form-group"><label for="email">Email</label><input expr67="expr67" id="email" name="email" type="email" class="form-control" autocomplete="cera-off" placeholder="email@eksempel.dk" required/><div class="invalid-feedback">\n        Ugyldig Email.\n      </div></div><div class="form-group"><label for="password">Adgangskode</label><input expr68="expr68" id="password" name="password" type="password" class="form-control" autocomplete="new-password" placeholder="Adgangskode" value/><div class="invalid-feedback">\n        Adgangskode skal udfyldes.\n      </div></div><selectrole expr69="expr69"></selectrole><div class="form-group"><button expr70="expr70" class="btn btn-primary" type="submit">Gem</button></div><div class="form-group"><div id="error"></div></div></form>',
      [{
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return scope.props.userid==0;
        },

        'redundantAttribute': 'expr63',
        'selector': '[expr63]',
        'template': template('Opret bruger', [])
      }, {
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return scope.props.userid!=0;
        },

        'redundantAttribute': 'expr64',
        'selector': '[expr64]',
        'template': template('Rediger bruger', [])
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'selectemployee';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'selectedid',

          'evaluate': function(scope) {
            return scope.state.user.EmployeeID;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.selectEmployee;
          }
        }],

        'redundantAttribute': 'expr65',
        'selector': '[expr65]'
      }, {
        'redundantAttribute': 'expr66',
        'selector': '[expr66]',

        'expressions': [{
          'type': expressionTypes.VALUE,

          'evaluate': function(scope) {
            return scope.state.user.Username;
          }
        }]
      }, {
        'redundantAttribute': 'expr67',
        'selector': '[expr67]',

        'expressions': [{
          'type': expressionTypes.VALUE,

          'evaluate': function(scope) {
            return scope.state.user.Email;
          }
        }]
      }, {
        'redundantAttribute': 'expr68',
        'selector': '[expr68]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'required',

          'evaluate': function(scope) {
            return scope.props.userid==0;
          }
        }]
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'selectrole';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'selectedid',

          'evaluate': function(scope) {
            return scope.state.user.Role;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.selectRole;
          }
        }],

        'redundantAttribute': 'expr69',
        'selector': '[expr69]'
      }, {
        'redundantAttribute': 'expr70',
        'selector': '[expr70]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.submit();
          }
        }]
      }]
    );
  },

  'name': 'user'
};