import utils from 'utils'

export default {
  'css': `employeestats .bold,[is="employeestats"] .bold{ font-weight: bold; }`,

  'exports': {
    onBeforeMount() {
      // this.state = {
      //     sumFees: 0,
      //     sumHours: 0
      // }
      // this.props.employee.forEach(function(item) {
      //     this.state.sumFees += item.SumFee
      //     this.state.sumHours += item.SumHours
      // })
    },

    onMounted() {
      console.log(this.props)
    },

    // // Format number nicely with thousand separators
    // numberWithSeparator(x) {
    //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // }

    // Format number nicely with thousand separators
    numberWithSeparator(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<table class="table table-sm"><tbody expr27="expr27"></tbody><tbody expr45="expr45"></tbody></table>',
      [{
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return !scope.props.employee.SendsInvoice;
        },

        'redundantAttribute': 'expr27',
        'selector': '[expr27]',

        'template': template(
          '<tr><th>Opgavetype</th><th style="text-align:center">Antal</th><th style="text-align:right">Honorar</th><th style="text-align:right">Timer</th></tr><tr expr28="expr28"></tr><tr expr34="expr34"></tr><th colspan="4">Totaler</th><tr expr38="expr38" class="bold"></tr><tr expr40="expr40" class="bold"></tr><tr class="bold"><td colspan="3">Honorar ialt</td><td expr42="expr42" style="text-align:right"> </td></tr><tr class="bold"><td colspan="3">Honorar (ex ferie)</td><td expr43="expr43" style="text-align:right"> </td></tr><tr class="bold"><td colspan="3">Feriepenge</td><td expr44="expr44" style="text-align:right"> </td></tr>',
          [{
            'type': bindingTypes.EACH,
            'getKey': null,
            'condition': null,

            'template': template(
              '<td expr29="expr29"> </td><td expr30="expr30" style="text-align:center"> </td><td expr31="expr31" style="text-align:right"> </td><td expr32="expr32" style="text-align:right"></td><td expr33="expr33" style="text-align:right"></td>',
              [{
                'redundantAttribute': 'expr29',
                'selector': '[expr29]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return scope.item.ProductName;
                  }
                }]
              }, {
                'redundantAttribute': 'expr30',
                'selector': '[expr30]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return scope.item.Quantity;
                  }
                }]
              }, {
                'redundantAttribute': 'expr31',
                'selector': '[expr31]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [scope.numberWithSeparator(scope.item.SumFee), ' kr.'].join('');
                  }
                }]
              }, {
                'type': bindingTypes.IF,

                'evaluate': function(scope) {
                  return scope.props.employee.ShowHours;
                },

                'redundantAttribute': 'expr32',
                'selector': '[expr32]',

                'template': template(' ', [{
                  'expressions': [{
                    'type': expressionTypes.TEXT,
                    'childNodeIndex': 0,

                    'evaluate': function(scope) {
                      return scope.item.SumHours.toFixed(1);
                    }
                  }]
                }])
              }, {
                'type': bindingTypes.IF,

                'evaluate': function(scope) {
                  return !scope.props.employee.ShowHours;
                },

                'redundantAttribute': 'expr33',
                'selector': '[expr33]',
                'template': template('-', [])
              }]
            ),

            'redundantAttribute': 'expr28',
            'selector': '[expr28]',
            'itemName': 'item',
            'indexName': 'key',

            'evaluate': function(scope) {
              return scope.props.employee.Statistics;
            }
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return scope.props.employee.SumFee>0;
            },

            'redundantAttribute': 'expr34',
            'selector': '[expr34]',

            'template': template(
              '<td>Ialt</td><td></td><td expr35="expr35" style="text-align:right"> </td><td expr36="expr36" style="text-align:right"></td><td expr37="expr37" style="text-align:right"></td>',
              [{
                'redundantAttribute': 'expr35',
                'selector': '[expr35]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [scope.numberWithSeparator(scope.props.employee.SumFee), ' kr.'].join('');
                  }
                }]
              }, {
                'type': bindingTypes.IF,

                'evaluate': function(scope) {
                  return scope.props.employee.ShowHours;
                },

                'redundantAttribute': 'expr36',
                'selector': '[expr36]',

                'template': template(' ', [{
                  'expressions': [{
                    'type': expressionTypes.TEXT,
                    'childNodeIndex': 0,

                    'evaluate': function(scope) {
                      return scope.props.employee.SumHours>160?160.0:scope.props.employee.SumHours.toFixed(1);
                    }
                  }]
                }])
              }, {
                'type': bindingTypes.IF,

                'evaluate': function(scope) {
                  return !scope.props.employee.ShowHours;
                },

                'redundantAttribute': 'expr37',
                'selector': '[expr37]',
                'template': template('-', [])
              }]
            )
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return scope.props.employee.ShowMileage == true;
            },

            'redundantAttribute': 'expr38',
            'selector': '[expr38]',

            'template': template(
              '<td colspan="3">Kørsel ialt</td><td expr39="expr39" style="text-align:right"> </td>',
              [{
                'redundantAttribute': 'expr39',
                'selector': '[expr39]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [scope.props.employee.SumMileage.toFixed(1), ' km'].join('');
                  }
                }]
              }]
            )
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return scope.props.employee.ShowHours == true;
            },

            'redundantAttribute': 'expr40',
            'selector': '[expr40]',

            'template': template(
              '<td colspan="3">Timer ialt</td><td expr41="expr41" style="text-align:right"> </td>',
              [{
                'redundantAttribute': 'expr41',
                'selector': '[expr41]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [
                      scope.props.employee.SumHours>160?160.0:scope.props.employee.SumHours.toFixed(1),
                      ' timer'
                    ].join('');
                  }
                }]
              }]
            )
          }, {
            'redundantAttribute': 'expr42',
            'selector': '[expr42]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return [scope.numberWithSeparator(scope.props.employee.SumFee), ' kr'].join('');
              }
            }]
          }, {
            'redundantAttribute': 'expr43',
            'selector': '[expr43]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return [
                  scope.numberWithSeparator((scope.props.employee.SumFee/1.125).toFixed(0)),
                  ' kr'
                ].join('');
              }
            }]
          }, {
            'redundantAttribute': 'expr44',
            'selector': '[expr44]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return [
                  scope.numberWithSeparator((scope.props.employee.SumFee - (scope.props.employee.SumFee/1.125)).toFixed(0)),
                  ' kr'
                ].join('');
              }
            }]
          }]
        )
      }, {
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return scope.props.employee.SendsInvoice;
        },

        'redundantAttribute': 'expr45',
        'selector': '[expr45]',

        'template': template(
          '<tr><th>Opgavetype</th><th style="text-align:center">Antal</th><th style="text-align:right">Honorar</th></tr><tr expr46="expr46"></tr><tr expr50="expr50"></tr><th colspan="3">Totaler</th><tr class="bold"><td colspan="2">Honorar ialt</td><td expr52="expr52" style="text-align:right"> </td></tr>',
          [{
            'type': bindingTypes.EACH,
            'getKey': null,
            'condition': null,

            'template': template(
              '<td expr47="expr47"> </td><td expr48="expr48" style="text-align:center"> </td><td expr49="expr49" style="text-align:right"> </td>',
              [{
                'redundantAttribute': 'expr47',
                'selector': '[expr47]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return scope.item.ProductName;
                  }
                }]
              }, {
                'redundantAttribute': 'expr48',
                'selector': '[expr48]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return scope.item.Quantity;
                  }
                }]
              }, {
                'redundantAttribute': 'expr49',
                'selector': '[expr49]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [scope.numberWithSeparator(scope.item.SumFee), ' kr.'].join('');
                  }
                }]
              }]
            ),

            'redundantAttribute': 'expr46',
            'selector': '[expr46]',
            'itemName': 'item',
            'indexName': 'key',

            'evaluate': function(scope) {
              return scope.props.employee.Statistics;
            }
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return scope.props.employee.SumFee>0;
            },

            'redundantAttribute': 'expr50',
            'selector': '[expr50]',

            'template': template(
              '<td colspan="2">Ialt</td><td expr51="expr51" style="text-align:right"> </td>',
              [{
                'redundantAttribute': 'expr51',
                'selector': '[expr51]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [scope.numberWithSeparator(scope.props.employee.SumFee), ' kr.'].join('');
                  }
                }]
              }]
            )
          }, {
            'redundantAttribute': 'expr52',
            'selector': '[expr52]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return [scope.numberWithSeparator(scope.props.employee.SumFee), ' kr'].join('');
              }
            }]
          }]
        )
      }]
    );
  },

  'name': 'employeestats'
};