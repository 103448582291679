import * as riot from 'riot'
import utils from 'utils'
import EmployeeStats from '../components/employeestats.riot'
riot.register('employeestats', EmployeeStats)

export default {
  'css': null,

  'exports': {
    onBeforeMount() {
      let today = new Date()
      this.state = {
        user: JSON.parse(sessionStorage.getItem('user')),
        statistics: [], // Statistics data from backend
        fixedfees: [], // FixedFee data from backend
        selectedYear: today.getFullYear(), // Initial value
        selectedMonth: today.getMonth() + 1, // Initial value - Note getMonth() is zero-based
        isLoggedIn: utils.getCookie("session_token"),
      }
      if (!this.state.isLoggedIn) {
        window.location = "/login"
      }
    },

    onMounted() {
      this.getStatistics()
      this.getFixedFees()
    },

    // Format number nicely with thousand separators
    numberWithSeparator(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    // Callback for <selectperiod>
    setPeriod(year, month) {
      this.state.selectedYear = year
      this.state.selectedMonth = month
      this.getStatistics()
    },

    // Get Statistics from the api for the selected period
    getStatistics() {
      let url = '/api/statistics/' + this.state.selectedYear + '/' + this.state.selectedMonth
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignStatistics(jsonResponse)
          })
        })
        .catch(error => {
          // We couldn't get statistics, so just redirect to the login page
          // as it was probably an authentification error. But no matter what,
          // for now we cannot continue if get statistics fails.
          console.log("getStatistics error: ", error)
        })
    },

    assignStatistics(statistics) {
      this.state.statistics = statistics
      this.update()
    },

    // Get FixedFees from the api for the active employees
    getFixedFees() {
      let url = '/api/statistics/fixedfees'
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignFixedFees(jsonResponse)
          })
        })
        .catch(error => {
          // We couldn't get FixedFees, so just redirect to the login page
          // as it was probably an authentification error. But no matter what,
          // for now we cannot continue if get FixedFees fails.
          console.log("getFixedFees error: ", error)
        })
    },

    assignFixedFees(fixedfees) {
      this.state.fixedfees = fixedfees
      this.update()
    },

    // Approve the Fees. The backend will then send a mail to the accountants
    approve(e) {
      e.target.disabled = true
      e.target.textContent = "Godkendt"
      let url = '/api/statistics/approve'
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
        })
        .catch(error => {
          // We couldn't approve, so just redirect to the login page
          // as it was probably an authentification error. But no matter what,
          // for now we cannot continue if approve fails.
          console.log("approve error: ", error)
        })
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="container"><div class="row"><div class="col"><selectperiod expr0="expr0"></selectperiod></div></div><div class="row"><div class="col-sm"><p>Statistik opgøres fra 26. i måneden før til 25. i valgte måned</p></div></div><div expr1="expr1" class="row"></div><div expr2="expr2" class="row"></div></div>',
      [{
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'selectperiod';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'year',

          'evaluate': function(scope) {
            return scope.state.selectedYear;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'month',

          'evaluate': function(scope) {
            return scope.state.selecteMonth;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.setPeriod;
          }
        }],

        'redundantAttribute': 'expr0',
        'selector': '[expr0]'
      }, {
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return !scope.state.statistics && !scope.state.fixedfees;
        },

        'redundantAttribute': 'expr1',
        'selector': '[expr1]',

        'template': template(
          '<div class="col-sm"><h3>Der er ingen data for den valgte periode</h3></div>',
          []
        )
      }, {
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return scope.state.statistics || scope.state.fixedfees;
        },

        'redundantAttribute': 'expr2',
        'selector': '[expr2]',

        'template': template(
          '<div class="col-3"><div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical"><a class="nav-link active" id="pills-tab-home" data-toggle="pill" href="#home" role="tab" aria-controls="home" aria-selected="true">\n            Overblik\n          </a><a expr3="expr3" class="nav-link" data-toggle="pill" role="tab"></a></div></div><div class="col-9"><div class="tab-content" id="v-pills-tabContent"><div class="tab-pane show active" id="home" role="tabpanel" aria-labelledby="pills-tab-home"><h4>Overblik - Samtlige medarbejdere</h4><table class="table table-sm"><thead><th>Navn</th><th style="text-align:right">Honorar/Løn</th><th style="text-align:right">Honorar/Løn ex. ferie</th><th style="text-align:right">Feriepenge</th><th style="text-align:right">Kørsel</th><th style="text-align:right">Timer</th></thead><tbody><tr expr4="expr4"></tr><tr expr15="expr15"></tr></tbody></table><button expr20="expr20" class="btn btn-sm btn-success"></button></div><div expr21="expr21" class="tab-pane" role="tabpanel"></div></div></div>',
          [{
            'type': bindingTypes.EACH,
            'getKey': null,
            'condition': null,

            'template': template(' ', [{
              'expressions': [{
                'type': expressionTypes.TEXT,
                'childNodeIndex': 0,

                'evaluate': function(scope) {
                  return [scope.employee.Name].join('');
                }
              }, {
                'type': expressionTypes.ATTRIBUTE,
                'name': 'id',

                'evaluate': function(scope) {
                  return ['pills-tab-pane', scope.employee.ID].join('');
                }
              }, {
                'type': expressionTypes.ATTRIBUTE,
                'name': 'href',

                'evaluate': function(scope) {
                  return ['#pane', scope.employee.ID].join('');
                }
              }, {
                'type': expressionTypes.ATTRIBUTE,
                'name': 'aria-controls',

                'evaluate': function(scope) {
                  return scope.employee.Name;
                }
              }]
            }]),

            'redundantAttribute': 'expr3',
            'selector': '[expr3]',
            'itemName': 'employee',
            'indexName': null,

            'evaluate': function(scope) {
              return scope.state.statistics;
            }
          }, {
            'type': bindingTypes.EACH,
            'getKey': null,
            'condition': null,

            'template': template(
              '<td expr5="expr5"> </td><td expr6="expr6" style="text-align:right"> </td><template expr7="expr7"></template><template expr8="expr8"></template>',
              [{
                'redundantAttribute': 'expr5',
                'selector': '[expr5]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return scope.employee.Name;
                  }
                }]
              }, {
                'redundantAttribute': 'expr6',
                'selector': '[expr6]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [scope.numberWithSeparator(scope.employee.SumFee), ' kr.'].join('');
                  }
                }]
              }, {
                'type': bindingTypes.IF,

                'evaluate': function(scope) {
                  return scope.employee.SendsInvoice;
                },

                'redundantAttribute': 'expr7',
                'selector': '[expr7]',
                'template': template('<td colspan="4">Sender faktura</td>', [])
              }, {
                'type': bindingTypes.IF,

                'evaluate': function(scope) {
                  return !scope.employee.SendsInvoice;
                },

                'redundantAttribute': 'expr8',
                'selector': '[expr8]',

                'template': template(
                  '<td expr9="expr9" style="text-align:right"> </td><td expr10="expr10" style="text-align:right"> </td><td expr11="expr11" style="text-align:right"></td><td expr12="expr12" style="text-align:center"></td><td expr13="expr13" style="text-align:right"></td><td expr14="expr14" style="text-align:center"></td>',
                  [{
                    'redundantAttribute': 'expr9',
                    'selector': '[expr9]',

                    'expressions': [{
                      'type': expressionTypes.TEXT,
                      'childNodeIndex': 0,

                      'evaluate': function(scope) {
                        return [
                          scope.numberWithSeparator((scope.employee.SumFee/1.125).toFixed(0)),
                          ' kr.'
                        ].join('');
                      }
                    }]
                  }, {
                    'redundantAttribute': 'expr10',
                    'selector': '[expr10]',

                    'expressions': [{
                      'type': expressionTypes.TEXT,
                      'childNodeIndex': 0,

                      'evaluate': function(scope) {
                        return [
                          scope.numberWithSeparator((scope.employee.SumFee - (scope.employee.SumFee/1.125)).toFixed(0)),
                          ' kr.'
                        ].join('');
                      }
                    }]
                  }, {
                    'type': bindingTypes.IF,

                    'evaluate': function(scope) {
                      return scope.employee.ShowMileage;
                    },

                    'redundantAttribute': 'expr11',
                    'selector': '[expr11]',

                    'template': template(' ', [{
                      'expressions': [{
                        'type': expressionTypes.TEXT,
                        'childNodeIndex': 0,

                        'evaluate': function(scope) {
                          return [scope.employee.SumMileage.toFixed(1), ' km'].join('');
                        }
                      }]
                    }])
                  }, {
                    'type': bindingTypes.IF,

                    'evaluate': function(scope) {
                      return !scope.employee.ShowMileage;
                    },

                    'redundantAttribute': 'expr12',
                    'selector': '[expr12]',
                    'template': template('-', [])
                  }, {
                    'type': bindingTypes.IF,

                    'evaluate': function(scope) {
                      return scope.employee.ShowHours;
                    },

                    'redundantAttribute': 'expr13',
                    'selector': '[expr13]',

                    'template': template(' ', [{
                      'expressions': [{
                        'type': expressionTypes.TEXT,
                        'childNodeIndex': 0,

                        'evaluate': function(scope) {
                          return scope.employee.SumHours>160?160.0:scope.employee.SumHours.toFixed(1);
                        }
                      }]
                    }])
                  }, {
                    'type': bindingTypes.IF,

                    'evaluate': function(scope) {
                      return !scope.employee.ShowHours;
                    },

                    'redundantAttribute': 'expr14',
                    'selector': '[expr14]',
                    'template': template('-', [])
                  }]
                )
              }]
            ),

            'redundantAttribute': 'expr4',
            'selector': '[expr4]',
            'itemName': 'employee',
            'indexName': null,

            'evaluate': function(scope) {
              return scope.state.statistics;
            }
          }, {
            'type': bindingTypes.EACH,
            'getKey': null,
            'condition': null,

            'template': template(
              '<td expr16="expr16"> </td><td expr17="expr17" style="text-align:right"> </td><td expr18="expr18" style="text-align:right"> </td><td expr19="expr19" style="text-align:right"> </td><td style="text-align:center">-</td><td style="text-align:center">-</td>',
              [{
                'redundantAttribute': 'expr16',
                'selector': '[expr16]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return scope.fixedfee.Name;
                  }
                }]
              }, {
                'redundantAttribute': 'expr17',
                'selector': '[expr17]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [scope.numberWithSeparator(scope.fixedfee.MonthlyFee), ' kr.'].join('');
                  }
                }]
              }, {
                'redundantAttribute': 'expr18',
                'selector': '[expr18]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [
                      scope.numberWithSeparator((scope.fixedfee.MonthlyFee/1.125).toFixed(0)),
                      ' kr.'
                    ].join('');
                  }
                }]
              }, {
                'redundantAttribute': 'expr19',
                'selector': '[expr19]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return [
                      scope.numberWithSeparator((scope.fixedfee.MonthlyFee - (scope.fixedfee.MonthlyFee/1.125)).toFixed(0)),
                      ' kr.'
                    ].join('');
                  }
                }]
              }]
            ),

            'redundantAttribute': 'expr15',
            'selector': '[expr15]',
            'itemName': 'fixedfee',
            'indexName': null,

            'evaluate': function(scope) {
              return scope.state.fixedfees;
            }
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return scope.state.user.Role==1 || scope.state.user.Role==2;
            },

            'redundantAttribute': 'expr20',
            'selector': '[expr20]',

            'template': template('Godkend', [{
              'expressions': [{
                'type': expressionTypes.EVENT,
                'name': 'onclick',

                'evaluate': function(scope) {
                  return scope.approve;
                }
              }]
            }])
          }, {
            'type': bindingTypes.EACH,
            'getKey': null,
            'condition': null,

            'template': template(
              '<h4 expr22="expr22"> </h4><employeestats expr23="expr23"></employeestats>',
              [{
                'expressions': [{
                  'type': expressionTypes.ATTRIBUTE,
                  'name': 'id',

                  'evaluate': function(scope) {
                    return ['pane', scope.employee.ID].join('');
                  }
                }, {
                  'type': expressionTypes.ATTRIBUTE,
                  'name': 'aria-labelledby',

                  'evaluate': function(scope) {
                    return ['pills-tab-pane', scope.employee.ID].join('');
                  }
                }]
              }, {
                'redundantAttribute': 'expr22',
                'selector': '[expr22]',

                'expressions': [{
                  'type': expressionTypes.TEXT,
                  'childNodeIndex': 0,

                  'evaluate': function(scope) {
                    return ['Månedstotaler for ', scope.employee.Name].join('');
                  }
                }]
              }, {
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'employeestats';
                },

                'slots': [],

                'attributes': [{
                  'type': expressionTypes.ATTRIBUTE,
                  'name': 'employee',

                  'evaluate': function(scope) {
                    return scope.employee;
                  }
                }],

                'redundantAttribute': 'expr23',
                'selector': '[expr23]'
              }]
            ),

            'redundantAttribute': 'expr21',
            'selector': '[expr21]',
            'itemName': 'employee',
            'indexName': null,

            'evaluate': function(scope) {
              return scope.state.statistics;
            }
          }]
        )
      }]
    );
  },

  'name': 'statistics'
};