import * as riot from 'riot'
import approvehours from '../components/approvehours.riot'
import approvefiles from '../components/approvefiles.riot'
import selectemployee from '../components/selectemployee.riot'

export default {
  'css': `approve #approve-error,[is="approve"] #approve-error{ display:none; }`,

  'exports': {
    components: {
        approvehours,
        approvefiles,
        selectemployee
    },

    onBeforeMount() {
        let today = new Date()
        let user = JSON.parse(sessionStorage.getItem('user'))
        this.state = {
            user: user,
            selectedEmployeeID: user.EmployeeID,
            selectedYear: today.getFullYear(), // Initial value
            selectedMonth: today.getMonth()+1, // Initial value - Note getMonth() is zero-based
            reportingHours: [],
            reportingFiles: []
        }
        this.getReportingHours()
        this.getReportingFiles()
    },

    onMounted() {
        this.update()
    },

    // Callback for <selectperiod>
    setPeriod(year,month) {
        this.state.selectedYear = year
        this.state.selectedMonth = month
        this.getReportingHours()
        this.getReportingFiles()
        this.update()
    },

    // Callback for <selectemployee>
    setEmployee(employee) {
        this.state.selectedEmployeeID = employee.ID
        this.getReportingHours()
        this.getReportingFiles()
        this.update()
    },

    getReporting(reportingType) {
        let url = '/api/reporting?'
        url += this.state.selectedYear ? 'year=' + parseInt(this.state.selectedYear) + "&" : ""
        url += this.state.selectedMonth ? 'month=' + parseInt(this.state.selectedMonth) + "&" : ""
        url += this.state.selectedEmployeeID ? 'employeeid=' + parseInt(this.state.selectedEmployeeID) + "&" : ""
        url += 'type=' + reportingType
        fetch(url, {
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response) => {
            if (response.status != 200) {
                return Promise.reject(response)
            }
            response.json().then((jsonResponse) => {
                switch(reportingType) {
                    case 1: this.assignHours(jsonResponse)
                            break
                    case 2: this.assignFiles(jsonResponse)
                            break
                }
            })
        })
        .catch( error => { 
            let errortag = document.getElementById('reporting-error')
            errortag.style.display = "block"
            errortag.innerHTML = error
            console.log("getReporting Error: ", error)
            //window.location = "/login"
        } )
    },

    // Fetch hour records for the selected period
    getReportingHours() {
        this.getReporting(1) // Type 1 = Hours
    },

    // Fetch file records for the selected period
    getReportingFiles() {
        this.getReporting(2) // Type 2 = files
    },

    // Assign the hours to state
    assignHours(hours) {
        this.state.reportingHours = hours
        this.update()
    },

    // Assign the files to state
    assignFiles(files) {
        this.state.reportingFiles = files
        this.update()
    },

    updateApprovals(reportings) {
        // Loop through reportings and send updated data to backend
        console.log("updateApprovals")
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="container"><div><h3>Godkend timer/bilag</h3><div class="row"><div class="col"><selectperiod expr51="expr51"></selectperiod></div></div><div class="row"><div class="col"><form class="form-inline"><selectemployee expr52="expr52" type="bookable"></selectemployee></form></div></div><ul class="nav nav-tabs" id="myTab" role="tablist"><li class="nav-item"><a class="nav-link active" id="files-tab" data-toggle="tab" href="#files" role="tab" aria-controls="files" aria-selected="true">Bilag</a></li><li class="nav-item"><a class="nav-link" id="hours-tab" data-toggle="tab" href="#hours" role="tab" aria-controls="hours" aria-selected="false">Timer</a></li></ul><div class="tab-content" id="myTabContent"><div class="tab-pane fade show active" id="files" role="tabpanel" aria-labelledby="files-tab"><approvefiles expr53="expr53"></approvefiles></div><div class="tab-pane fade" id="hours" role="tabpanel" aria-labelledby="hours-tab"><approvehours expr54="expr54"></approvehours></div></div><div id="approve-error" class="alert alert-danger" role="alert"></div></div></div>',
      [{
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'selectperiod';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'year',

          'evaluate': function(scope) {
            return scope.state.selectedYear;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'month',

          'evaluate': function(scope) {
            return scope.state.selecteMonth;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.setPeriod;
          }
        }],

        'redundantAttribute': 'expr51',
        'selector': '[expr51]'
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'selectemployee';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'selected',

          'evaluate': function(scope) {
            return scope.state.selectedEmployeeID;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.setEmployee;
          }
        }],

        'redundantAttribute': 'expr52',
        'selector': '[expr52]'
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'approvefiles';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'employeeid',

          'evaluate': function(scope) {
            return scope.state.selectedEmployeeID;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'reportingfiles',

          'evaluate': function(scope) {
            return scope.state.reportingFiles;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.updateApprovals;
          }
        }],

        'redundantAttribute': 'expr53',
        'selector': '[expr53]'
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'approvehours';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'employeeid',

          'evaluate': function(scope) {
            return scope.state.selectedEmployeeID;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'reportinghours',

          'evaluate': function(scope) {
            return scope.state.reportingHours;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.updateApprovals;
          }
        }],

        'redundantAttribute': 'expr54',
        'selector': '[expr54]'
      }]
    );
  },

  'name': 'approve'
};