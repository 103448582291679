import { route, router, setBase } from '@riotjs/route'
import selectemployee from '../components/selectemployee.riot'

export default {
  'css': null,

  'exports': {
    components: {
      selectemployee
    },

    onBeforeMount() {
      this.state = {
        user: JSON.parse(sessionStorage.getItem('employee')),
        fixedfeeemployees: [],
        currentEmployee: {
          Id: 0,
          EmployeeId: 0,
          Name: "",
          MonthlyFee: 0,
          Active: true,
        }
      };
      this.getEmployees();
    },

    onMounted() {
      this.update()
    },

    // Get Employee list from the api
    getEmployees() {
      let url = '/api/fixedfeeemployees'
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignEmployees(jsonResponse)
          })
        })
        .catch(error => {
          console.log("Fetch FixedFeeEmployees Error: ", error)
          //window.location = "/login"
        })
    },

    assignEmployees(fixedfeeemployees) {
      this.state.fixedfeeemployees = fixedfeeemployees
      this.update()
    },

    // Copy data from the selected employee over to currentEmployee
    // and open it in the modal window
    editEmployee(index) {
      let selectedEmployee = this.state.fixedfeeemployees[index];
      let currentEmployee = this.state.currentEmployee;
      return function (e) {
        currentEmployee.Id = selectedEmployee.Id;
        currentEmployee.Name = selectedEmployee.Name;
        currentEmployee.EmployeeId = selectedEmployee.EmployeeId;
        currentEmployee.MonthlyFee = selectedEmployee.MonthlyFee;
        currentEmployee.Active = selectedEmployee.Active;
        // Populate modal and open it with the selected Employee
        document.getElementById('employeename').value = currentEmployee.Name;
        document.getElementById('employeefee').value = currentEmployee.MonthlyFee;
        document.getElementById('employeeactive').checked = currentEmployee.Active;
        document.getElementById('employeeSelection').value = currentEmployee.EmployeeId;
        $('#employeeModal').modal('show');
      }
    },

    createEmployee(e) {
      e.preventDefault()
      window.location = "/#employee/0"
    },

    deleteEmployee(employee) {
      let successfunc = this.getEmployees;
      return function (e) {
        e.preventDefault()
        let url = '/api/fixedfeeemployees/' + employee.Id
        fetch(url, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
          .then((response) => {
            if (response.status != 200) {
              return Promise.reject(response)
            }
            successfunc();
          })
          .catch(error => {
            console.log("deleteEmployee fetch Error: ", error)
            if (error.status == 401) {
              let errorField = document.getElementById('error')
              errorField.innerHTML = "Der skete en fejl ved sletning af denne medarbejder: " + error.responseText
              //window.location = "/login"
            }
          })
      }
    },

    updateEmployeeId(employee) {
      this.state.currentEmployee.EmployeeId = employee.ID
    },

    updateCurrentName(e) {
      this.state.currentEmployee.Name = e.target.value
    },

    updateCurrentFee(e) {
      this.state.currentEmployee.MonthlyFee = parseInt(e.target.value)
    },

    updateCurrentActive(e) {
      this.state.currentEmployee.Active = e.target.checked
    },

    // Update Active status of state.fixedfeeemployees at index
    updateActive(index) {
      let employees = this.state.fixedfeeemployees;
      let saveEmployeeByIndex = this.saveEmployeeByIndex;
      return function (e) {
        //e.preventDefault()
        employees[index].Active = e.target.checked;
        saveEmployeeByIndex(index);
      }
    },

    saveCurrentEmployee() {
      this.saveEmployee(this.state.currentEmployee)
    },

    saveEmployeeByIndex(index) {
      this.saveEmployee(this.state.fixedfeeemployees[index])
    },

    saveEmployee(employee) {
      let successfunc = this.getEmployees;
      let errortag = document.getElementById('error')
      fetch('/api/fixedfeeemployees', {
        method: employee.Id == 0 ? "POST" : "PUT", // For create or update
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "Id": employee.Id,
          "Name": employee.Name,
          "EmployeeId": employee.EmployeeId,
          "MonthlyFee": employee.MonthlyFee,
          "Active": employee.Active,
        })
      })
        .then(response => {
          if (response.status != 200) {
            console.log("Something went wrong:", response.responseText)
            errortag.innerHTML = response.responseText
            return;
          } else {
            $('#employeeModal').modal('hide');
            successfunc();
          }
        })
        .catch((error) => {
          errortag.innterHTML = error.responseText
          console.error("saveEmployee: fetch error: ", error)
        })
    },

    // Format number nicely with '.' as thousand separators
    numberWithSeparator(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="d-flex"><div class="p-2"><h3>Fastlønnede medarbejdere</h3></div><div class="ml-auto p-2"><button type="button" class="btn btn-outline-secondary btn-sm pull-right" data-toggle="modal" data-target="#employeeModal">Tilføj medarbejder</button></div></div><div id="error"></div><table class="table table-sm"><thead><tr><th>Navn</th><th>Månedsløn</th><th style="text-align:center">Aktiv</th><th></th></tr></thead><tbody><tr expr77="expr77"></tr></tbody></table><div class="modal fade" id="employeeModal" tabindex="-1" aria-labelledby="employeeModalLabel" aria-hidden="true"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><h5 class="modal-title" id="employeeModalLabel">Tilføj medarbejder</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="modal-body"><form><div class="form-group"><selectemployee expr83="expr83" type="active"></selectemployee></div><div class="form-group"><label for="employeename">Navn</label><input expr84="expr84" type="text" class="form-control" id="employeename" placeholder="For- og efternavn"/></div><div class="form-group"><label for="employeefee">Månedsløn</label><input expr85="expr85" type="text" class="form-control" id="employeefee" placeholder="Fast månedsløn"/></div><div class="form-group form-check"><input expr86="expr86" type="checkbox" class="form-check-input" id="employeeactive"/><label class="form-check-label" for="employeeactive">Aktiv</label></div></form></div><div class="modal-footer"><button type="button" class="btn btn-secondary" data-dismiss="modal">Luk</button><button expr87="expr87" type="button" class="btn btn-primary">Gem</button></div></div></div></div>',
      [{
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(
          '<td expr78="expr78"> </td><td expr79="expr79"> </td><td style="text-align:center"><input expr80="expr80" type="checkbox" class="form-check-input"/></td><td style="text-align:right;"><button expr81="expr81" class="btn btn-secondary btn-sm">Redigér</button>&nbsp;\n          <button expr82="expr82" class="btn btn-danger btn-sm">Slet</button></td>',
          [{
            'redundantAttribute': 'expr78',
            'selector': '[expr78]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.employee.Name;
              }
            }]
          }, {
            'redundantAttribute': 'expr79',
            'selector': '[expr79]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return [scope.numberWithSeparator(scope.employee.MonthlyFee), ' kr.'].join('');
              }
            }]
          }, {
            'redundantAttribute': 'expr80',
            'selector': '[expr80]',

            'expressions': [{
              'type': expressionTypes.ATTRIBUTE,
              'name': 'checked',

              'evaluate': function(scope) {
                return scope.employee.Active;
              }
            }, {
              'type': expressionTypes.EVENT,
              'name': 'onchange',

              'evaluate': function(scope) {
                return scope.updateActive(scope.i);
              }
            }]
          }, {
            'redundantAttribute': 'expr81',
            'selector': '[expr81]',

            'expressions': [{
              'type': expressionTypes.EVENT,
              'name': 'onclick',

              'evaluate': function(scope) {
                return scope.editEmployee(scope.i);
              }
            }]
          }, {
            'redundantAttribute': 'expr82',
            'selector': '[expr82]',

            'expressions': [{
              'type': expressionTypes.EVENT,
              'name': 'onclick',

              'evaluate': function(scope) {
                return scope.deleteEmployee(scope.employee);
              }
            }]
          }]
        ),

        'redundantAttribute': 'expr77',
        'selector': '[expr77]',
        'itemName': 'employee',
        'indexName': 'i',

        'evaluate': function(scope) {
          return scope.state.fixedfeeemployees;
        }
      }, {
        'type': bindingTypes.TAG,
        'getComponent': getComponent,

        'evaluate': function(scope) {
          return 'selectemployee';
        },

        'slots': [],

        'attributes': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'selectedid',

          'evaluate': function(scope) {
            return scope.state.currentEmployee.EmployeeId;
          }
        }, {
          'type': expressionTypes.ATTRIBUTE,
          'name': 'callback',

          'evaluate': function(scope) {
            return scope.updateEmployeeId;
          }
        }],

        'redundantAttribute': 'expr83',
        'selector': '[expr83]'
      }, {
        'redundantAttribute': 'expr84',
        'selector': '[expr84]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onblur',

          'evaluate': function(scope) {
            return scope.updateCurrentName;
          }
        }]
      }, {
        'redundantAttribute': 'expr85',
        'selector': '[expr85]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onblur',

          'evaluate': function(scope) {
            return scope.updateCurrentFee;
          }
        }]
      }, {
        'redundantAttribute': 'expr86',
        'selector': '[expr86]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'checked',

          'evaluate': function(scope) {
            return scope.state.currentEmployee.Active;
          }
        }, {
          'type': expressionTypes.EVENT,
          'name': 'onchange',

          'evaluate': function(scope) {
            return scope.updateCurrentActive;
          }
        }]
      }, {
        'redundantAttribute': 'expr87',
        'selector': '[expr87]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.saveCurrentEmployee;
          }
        }]
      }]
    );
  },

  'name': 'fixedfeeemployees'
};