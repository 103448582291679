import { route, router, setBase } from '@riotjs/route'
import utils from 'utils'

export default {
  'css': null,

  'exports': {
    onBeforeMount() {
      this.state.isLoggedIn = utils.getCookie("session_token")
      this.state.user = {}
      if (this.state.isLoggedIn) {
        this.getUser()
      } 
    },

    onMounted() {
      this.update()
    },

    // Fetch user information from backend
    getUser() {
      let url = '/api/users/current'
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignUser(jsonResponse)
          })
        })
        .catch(error => {
          console.log("app.getUser fetch Error: ", error)
          if (error.status == 401) {
            let errorField = document.getElementById('error')
            errorField.innerHTML = "Der skete en fejl ved hentning af denne bruger: " + error.responseText
            window.location = "/login"
          }
        })
    },

    assignUser(user) {
      this.state.user = user
      sessionStorage.setItem('user', JSON.stringify(user))
      this.update()
    },

    logout(e) {
      e.preventDefault()
      sessionStorage.clear()
      fetch('/api/logout', {
        method: 'POST',
      })
        .then(response => {
          this.state.isLoggedIn = false
          this.state.user = {}
          window.location = "/"
        })
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template('<router expr0="expr0"></router>', [{
      'type': bindingTypes.TAG,
      'getComponent': getComponent,

      'evaluate': function(scope) {
        return 'router';
      },

      'slots': [{
        'id': 'default',
        'html': '<nav class="navbar navbar-expand-lg navbar-light bg-light"><a class="navbar-brand" href="/">CERA ApS</a><button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button><div class="collapse navbar-collapse" id="navbarSupportedContent"><ul class="navbar-nav mr-auto"><li expr1="expr1" class="nav-item"></li><li expr2="expr2" class="nav-item dropdown"></li><li expr3="expr3" class="nav-item"></li><li expr4="expr4" class="nav-item"></li></ul><ul class="navbar-nav"><li expr5="expr5" class="nav-item active"></li><li expr6="expr6" class="nav-item"></li></ul></div></nav><div class="container"><route expr8="expr8" path="/"></route><route expr10="expr10" path="/login"></route><route expr12="expr12" path="/users"></route><route expr14="expr14" path="/#user/:userid"></route><route expr16="expr16" path="/statistics"></route><route expr18="expr18" path="/reporting"></route><route expr20="expr20" path="/approve"></route><route expr22="expr22" path="/fixedfeeemployees"></route></div>',

        'bindings': [{
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn;
          },

          'redundantAttribute': 'expr1',
          'selector': '[expr1]',
          'template': template('<a class="nav-link" href="/statistics">Statistik</a>', [])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr2',
          'selector': '[expr2]',

          'template': template(
            '<a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n              Timer/Bilag\n            </a><div class="dropdown-menu" aria-labelledby="navbarDropdown"><a class="dropdown-item" href="/reporting">Indrapportering</a><a class="dropdown-item" href="/approve">Godkendelse</a></div>',
            []
          )
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr3',
          'selector': '[expr3]',
          'template': template('<a class="nav-link" href="/fixedfeeemployees">Medarbejdere</a>', [])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr4',
          'selector': '[expr4]',
          'template': template('<a class="nav-link" href="/users">Brugere</a>', [])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return !scope.state.isLoggedIn;
          },

          'redundantAttribute': 'expr5',
          'selector': '[expr5]',
          'template': template('<a class="nav-link" href="/login">Login</a>', [])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn;
          },

          'redundantAttribute': 'expr6',
          'selector': '[expr6]',

          'template': template('<a expr7="expr7" class="nav-link" href="#"> </a>', [{
            'redundantAttribute': 'expr7',
            'selector': '[expr7]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return ['Logout (', scope.state.user.Username, ')'].join('');
              }
            }, {
              'type': expressionTypes.EVENT,
              'name': 'onclick',

              'evaluate': function(scope) {
                return scope.logout;
              }
            }]
          }])
        }, {
          'type': bindingTypes.TAG,
          'getComponent': getComponent,

          'evaluate': function(scope) {
            return 'route';
          },

          'slots': [{
            'id': 'default',
            'html': '<frontpage expr9="expr9"></frontpage>',

            'bindings': [{
              'type': bindingTypes.TAG,
              'getComponent': getComponent,

              'evaluate': function(scope) {
                return 'frontpage';
              },

              'slots': [],
              'attributes': [],
              'redundantAttribute': 'expr9',
              'selector': '[expr9]'
            }]
          }],

          'attributes': [],
          'redundantAttribute': 'expr8',
          'selector': '[expr8]'
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return !scope.state.isLoggedIn;
          },

          'redundantAttribute': 'expr10',
          'selector': '[expr10]',

          'template': template(null, [{
            'type': bindingTypes.TAG,
            'getComponent': getComponent,

            'evaluate': function(scope) {
              return 'route';
            },

            'slots': [{
              'id': 'default',
              'html': '<login expr11="expr11"></login>',

              'bindings': [{
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'login';
                },

                'slots': [],
                'attributes': [],
                'redundantAttribute': 'expr11',
                'selector': '[expr11]'
              }]
            }],

            'attributes': []
          }])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr12',
          'selector': '[expr12]',

          'template': template(null, [{
            'type': bindingTypes.TAG,
            'getComponent': getComponent,

            'evaluate': function(scope) {
              return 'route';
            },

            'slots': [{
              'id': 'default',
              'html': '<users expr13="expr13"></users>',

              'bindings': [{
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'users';
                },

                'slots': [],
                'attributes': [],
                'redundantAttribute': 'expr13',
                'selector': '[expr13]'
              }]
            }],

            'attributes': []
          }])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr14',
          'selector': '[expr14]',

          'template': template(null, [{
            'type': bindingTypes.TAG,
            'getComponent': getComponent,

            'evaluate': function(scope) {
              return 'route';
            },

            'slots': [{
              'id': 'default',
              'html': '<user expr15="expr15"></user>',

              'bindings': [{
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'user';
                },

                'slots': [],

                'attributes': [{
                  'type': expressionTypes.ATTRIBUTE,
                  'name': 'userid',

                  'evaluate': function(scope) {
                    return scope.route.params.userid;
                  }
                }],

                'redundantAttribute': 'expr15',
                'selector': '[expr15]'
              }]
            }],

            'attributes': []
          }])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn;
          },

          'redundantAttribute': 'expr16',
          'selector': '[expr16]',

          'template': template(null, [{
            'type': bindingTypes.TAG,
            'getComponent': getComponent,

            'evaluate': function(scope) {
              return 'route';
            },

            'slots': [{
              'id': 'default',
              'html': '<statistics expr17="expr17"></statistics>',

              'bindings': [{
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'statistics';
                },

                'slots': [],
                'attributes': [],
                'redundantAttribute': 'expr17',
                'selector': '[expr17]'
              }]
            }],

            'attributes': []
          }])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr18',
          'selector': '[expr18]',

          'template': template(null, [{
            'type': bindingTypes.TAG,
            'getComponent': getComponent,

            'evaluate': function(scope) {
              return 'route';
            },

            'slots': [{
              'id': 'default',
              'html': '<reporting expr19="expr19"></reporting>',

              'bindings': [{
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'reporting';
                },

                'slots': [],
                'attributes': [],
                'redundantAttribute': 'expr19',
                'selector': '[expr19]'
              }]
            }],

            'attributes': []
          }])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr20',
          'selector': '[expr20]',

          'template': template(null, [{
            'type': bindingTypes.TAG,
            'getComponent': getComponent,

            'evaluate': function(scope) {
              return 'route';
            },

            'slots': [{
              'id': 'default',
              'html': '<approve expr21="expr21"></approve>',

              'bindings': [{
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'approve';
                },

                'slots': [],
                'attributes': [],
                'redundantAttribute': 'expr21',
                'selector': '[expr21]'
              }]
            }],

            'attributes': []
          }])
        }, {
          'type': bindingTypes.IF,

          'evaluate': function(scope) {
            return scope.state.isLoggedIn && (scope.state.user.Role == 2 || scope.state.user.Role == 3);
          },

          'redundantAttribute': 'expr22',
          'selector': '[expr22]',

          'template': template(null, [{
            'type': bindingTypes.TAG,
            'getComponent': getComponent,

            'evaluate': function(scope) {
              return 'route';
            },

            'slots': [{
              'id': 'default',
              'html': '<fixedfeeemployees expr23="expr23"></fixedfeeemployees>',

              'bindings': [{
                'type': bindingTypes.TAG,
                'getComponent': getComponent,

                'evaluate': function(scope) {
                  return 'fixedfeeemployees';
                },

                'slots': [],
                'attributes': [],
                'redundantAttribute': 'expr23',
                'selector': '[expr23]'
              }]
            }],

            'attributes': []
          }])
        }]
      }],

      'attributes': [],
      'redundantAttribute': 'expr0',
      'selector': '[expr0]'
    }]);
  },

  'name': 'app'
};