export default {
  'css': `selectemployee .form-group select, selectemployee .form-group label, selectemployee .form-group button { margin-right: 10px; }`,

  'exports': {
    onBeforeMount() {
      let user = JSON.parse(sessionStorage.getItem('user'))
      this.state = {
        user: user,
        employees: [],
      }
      switch (this.props.type) {
        case 'bookable': 
          this.getBookableEmployees();
          break;
        case 'sendsinvoice':
          this.getSendsInvoiceEmployees();
          break;
        case 'active':
          this.getActiveEmployees();
          break;
      }
    },

    onMounted() {
      this.update()
    },

    getBookableEmployees() {
      let url = '/api/employees/bookable'
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignEmployees(jsonResponse)
          })
        })
        .catch(error => {
          console.log("getBookableEmployees fetch Error: ", error)
          if (error.status == 401) {
            let errorField = document.getElementById('error')
            errorField.innerHTML = "Der skete en fejl ved hentning af medarbejdere: " + error.responseText
            window.location = "/login"
          }
        })
    },

    getSendsInvoiceEmployees() {
      let url = '/api/employees/sendsinvoice'
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignEmployees(jsonResponse)
          })
        })
        .catch(error => {
          console.log("getSendsInvoiceEmployees fetch Error: ", error)
          if (error.status == 401) {
            let errorField = document.getElementById('error')
            errorField.innerHTML = "Der skete en fejl ved hentning af medarbejdere: " + error.responseText
            window.location = "/login"
          }
        })
    },

    getActiveEmployees() {
      let url = '/api/employees/active'
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignEmployees(jsonResponse)
          })
        })
        .catch(error => {
          console.log("getActiveEmployees fetch Error: ", error)
          if (error.status == 401) {
            let errorField = document.getElementById('error')
            errorField.innerHTML = "Der skete en fejl ved hentning af medarbejdere: " + error.responseText
            window.location = "/login"
          }
        })
    },

    assignEmployees(employees) {
      // Create a dummy entry
      employees.unshift({ ID: 0, Name: "-- Vælg medarbejder --", Email: "" })
      this.state.employees = employees
      this.update()
    },

    // Called When an employee is selected
    selectEmployee(e) {
      for (var i = 0; i < this.state.employees.length; i++) {
        if (this.state.employees[i].ID == e.target.value) {
          break
        }
      }
      this.update()
      this.props.callback(this.state.employees[i])
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="form-group"><label class="control-label" for="employeeSelection">Vælg CERAordre Medarbejder </label><select expr53="expr53" class="form-control" id="employeeSelection"><option expr54="expr54"></option></select></div>',
      [{
        'redundantAttribute': 'expr53',
        'selector': '[expr53]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onchange',

          'evaluate': function(scope) {
            return scope.selectEmployee;
          }
        }]
      }, {
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(' ', [{
          'expressions': [{
            'type': expressionTypes.TEXT,
            'childNodeIndex': 0,

            'evaluate': function(scope) {
              return [scope.employee.Name].join('');
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'value',

            'evaluate': function(scope) {
              return scope.employee.ID;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'selected',

            'evaluate': function(scope) {
              return scope.employee.ID==scope.props.selectedid;
            }
          }]
        }]),

        'redundantAttribute': 'expr54',
        'selector': '[expr54]',
        'itemName': 'employee',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.state.employees;
        }
      }]
    );
  },

  'name': 'selectemployee'
};