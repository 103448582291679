import utils from 'utils'

export default {
  'css': null,

  'exports': {
    onBeforeMount() {
        this.state.isLoggedIn = utils.getCookie("session_token")
    },

    onMounted() {
        this.update()
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="container"><div class="jumbotron"><h1 class="display-4">CERA Accounting</h1><p class="lead">\n                Dette er CERAs system til statistik og indrapportering.\n            </p><hr class="my-4"/><p>\n                Her findes bl.a. månedsstatistikker for medarbejdere til brug for lønberegning</p><p>\n                Der er desuden mulighed for at lave timeregistrering samt uploade bilag. Det kan f.eks. være parkeringsbilag eller øvrige former for udlæg.\n            </p><p class="lead"><a expr24="expr24" class="btn btn-primary btn-lg" href="/login" role="button"></a><a expr25="expr25" class="btn btn-primary btn-lg" href="/statistics" role="button"></a></p></div></div>',
      [{
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return !scope.state.isLoggedIn;
        },

        'redundantAttribute': 'expr24',
        'selector': '[expr24]',
        'template': template('Log ind', [])
      }, {
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return scope.state.isLoggedIn;
        },

        'redundantAttribute': 'expr25',
        'selector': '[expr25]',
        'template': template('Gå til Statistik', [])
      }]
    );
  },

  'name': 'frontpage'
};