export default {
  'css': `selectperiod .form-inline .form-group select,[is="selectperiod"] .form-inline .form-group select,selectperiod .form-inline .form-group label,[is="selectperiod"] .form-inline .form-group label,selectperiod .form-inline .form-group button,[is="selectperiod"] .form-inline .form-group button{ margin-right:10px; }`,

  'exports': {
    onBeforeMount() {
        let today = new Date()
        this.state = {
            today: today, // Todays date
            selectedYear: this.props.year ? this.props.year : today.getFullYear(),
            selectedMonth: this.props.month ? this.props.month : today.getMonth()+1,
            months: [], // List of months for select
            years: [], // List of years for select
        }
    },

    onMounted() {
        // Create array for year selection - going 5 years back
        var year = this.state.today.getFullYear();
        for ( var y = year; y>=(year-5); y--) {
            this.state.years.push(y)
        }
        let months = [
            "Januar",
            "Februar",
            "Marts",
            "April",
            "Maj",
            "Juni",
            "Juli",
            "August",
            "September",
            "Oktober",
            "November",
            "December"
        ]
        for (let i = 0; i < 12; i++) {
            this.state.months.push(months[i])
        }
        this.update()
    },

    // Callback for Month select
    changeMonth(e) {
        this.state.selectedMonth = e.target.value
        this.update()
        this.props.callback(this.state.selectedYear, this.state.selectedMonth)
    },

    // Callback for Year select
    changeYear(e) {
        this.state.selectedYear = e.target.value
        this.update()
        this.props.callback(this.state.selectedYear, this.state.selectedMonth)
    },

    // Callback for "Go to todays date" button
    gotoToday(e) {
        this.state.selectedYear = this.state.today.getFullYear(),
        this.state.selectedMonth = this.state.today.getMonth()+1, // Note getMonth() is zero-based
        this.update()
        this.props.callback(this.state.selectedYear, this.state.selectedMonth)
    },

    // Check if we current period is selected
    isThisPeriod() {
        if ( this.state.selectedMonth == this.state.today.getMonth()+1 &&
            this.state.selectedYear == this.state.today.getFullYear()) {
            return true
        }
        return false
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<form class="form-inline"><div class="form-group"><label class="control-label" for="statistics-selectedMonth">Vælg periode: Måned </label><select expr71="expr71" id="statistics-selectedMonth" class="form-control"><option expr72="expr72"></option></select></div><div class="form-group"><label class="control-label" for="statistics-selectedYear">År </label><select expr73="expr73" id="statistics-selectedYear" class="form-control"><option expr74="expr74"></option></select></div><div class="form-group"><button expr75="expr75" class="btn btn-success">Gå til dags dato</button></div><div expr76="expr76" class="form-group"></div></form>',
      [{
        'redundantAttribute': 'expr71',
        'selector': '[expr71]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'selected',

          'evaluate': function(scope) {
            return scope.state.selectedMonth;
          }
        }, {
          'type': expressionTypes.EVENT,
          'name': 'onchange',

          'evaluate': function(scope) {
            return scope.changeMonth;
          }
        }]
      }, {
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(' ', [{
          'expressions': [{
            'type': expressionTypes.TEXT,
            'childNodeIndex': 0,

            'evaluate': function(scope) {
              return scope.month;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'value',

            'evaluate': function(scope) {
              return scope.index+1;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'selected',

            'evaluate': function(scope) {
              return scope.state.selectedMonth==scope.index+1;
            }
          }]
        }]),

        'redundantAttribute': 'expr72',
        'selector': '[expr72]',
        'itemName': 'month',
        'indexName': 'index',

        'evaluate': function(scope) {
          return scope.state.months;
        }
      }, {
        'redundantAttribute': 'expr73',
        'selector': '[expr73]',

        'expressions': [{
          'type': expressionTypes.ATTRIBUTE,
          'name': 'selected',

          'evaluate': function(scope) {
            return scope.state.selectedYear;
          }
        }, {
          'type': expressionTypes.EVENT,
          'name': 'onchange',

          'evaluate': function(scope) {
            return scope.changeYear;
          }
        }]
      }, {
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(' ', [{
          'expressions': [{
            'type': expressionTypes.TEXT,
            'childNodeIndex': 0,

            'evaluate': function(scope) {
              return scope.year;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'value',

            'evaluate': function(scope) {
              return scope.year;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'selected',

            'evaluate': function(scope) {
              return scope.state.selectedYear==scope.year;
            }
          }]
        }]),

        'redundantAttribute': 'expr74',
        'selector': '[expr74]',
        'itemName': 'year',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.state.years;
        }
      }, {
        'redundantAttribute': 'expr75',
        'selector': '[expr75]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.gotoToday;
          }
        }]
      }, {
        'type': bindingTypes.IF,

        'evaluate': function(scope) {
          return scope.isThisPeriod();
        },

        'redundantAttribute': 'expr76',
        'selector': '[expr76]',
        'template': template(' (Indeværende periode)', [])
      }]
    );
  },

  'name': 'selectperiod'
};