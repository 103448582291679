import { route, router, setBase } from '@riotjs/route'

export default {
  'css': null,

  'exports': {
    onBeforeMount() {
      this.state = {
        user: JSON.parse(sessionStorage.getItem('user')),
        users: []
      }
      this.getUsers()
    },

    onMounted() {
      this.update()
    },

    // Get user list from the api
    getUsers() {
      let url = '/api/users'
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return Promise.reject(response)
          }
          response.json().then((jsonResponse) => {
            this.assignUsers(jsonResponse)
          })
        })
        .catch(error => {
          console.log("Fetch users Error: ", error)
          //window.location = "/login"
        })
    },

    assignUsers(users) {
      this.state.users = users
      this.update()
    },

    editUser(user) {
      return function (e) {
        console.log("editUser:",user);
        window.location = "/#user/" + user.ID
      }
    },

    createUser: (e) => {
      e.preventDefault()
      window.location = "/#user/0"
    },

    deleteUser(user) {
      let successfunc = this.getUsers
      return function (e) {
        e.preventDefault()
        let url = '/api/users/' + user.ID
        fetch(url, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        })
          .then((response) => {
            if (response.status != 200) {
              return Promise.reject(response)
            }
            successfunc()
          })
          .catch(error => {
            console.log("deleteUser fetch Error: ", error)
            if (error.status == 401) {
              let errorField = document.getElementById('error')
              errorField.innerHTML = "Der skete en fejl ved sletning af denne bruger: " + error.responseText
              //window.location = "/login"
            }
          })
      }
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="d-flex"><div class="p-2"><h3>Brugerliste</h3></div><div class="ml-auto p-2"><button expr55="expr55" class="btn btn-outline-secondary btn-sm pull-right">Opret ny bruger</button></div></div><div id="error"></div><table class="table table-sm"><thead><tr><th>Brugernavn</th><th>Email</th><th>Rolle</th><th>Medarbejder id</th><th></th></tr></thead><tbody><tr expr56="expr56"></tr></tbody></table>',
      [{
        'redundantAttribute': 'expr55',
        'selector': '[expr55]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.createUser;
          }
        }]
      }, {
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(
          '<td expr57="expr57"> </td><td expr58="expr58"> </td><td expr59="expr59"> </td><td expr60="expr60"> </td><td><button expr61="expr61" class="btn btn-secondary btn-sm">Redigér</button>&nbsp;\n          <button expr62="expr62" class="btn btn-danger btn-sm">Slet</button></td>',
          [{
            'redundantAttribute': 'expr57',
            'selector': '[expr57]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.user.Username;
              }
            }]
          }, {
            'redundantAttribute': 'expr58',
            'selector': '[expr58]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.user.Email;
              }
            }]
          }, {
            'redundantAttribute': 'expr59',
            'selector': '[expr59]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.user.Role;
              }
            }]
          }, {
            'redundantAttribute': 'expr60',
            'selector': '[expr60]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.user.EmployeeID;
              }
            }]
          }, {
            'redundantAttribute': 'expr61',
            'selector': '[expr61]',

            'expressions': [{
              'type': expressionTypes.EVENT,
              'name': 'onclick',

              'evaluate': function(scope) {
                return scope.editUser(scope.user);
              }
            }]
          }, {
            'redundantAttribute': 'expr62',
            'selector': '[expr62]',

            'expressions': [{
              'type': expressionTypes.EVENT,
              'name': 'onclick',

              'evaluate': function(scope) {
                return scope.deleteUser(scope.user);
              }
            }]
          }]
        ),

        'redundantAttribute': 'expr56',
        'selector': '[expr56]',
        'itemName': 'user',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.state.users;
        }
      }]
    );
  },

  'name': 'users'
};