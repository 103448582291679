//import moment from '../../node_modules/moment/dist/moment.js'
import utils from 'utils'

export default {
  'css': `reporthours #reportinghours-error,[is="reporthours"] #reportinghours-error{ display:none; }`,

  'exports': {
    onBeforeMount() {
        this.state.user = JSON.parse(sessionStorage.getItem('user'))
    },

    onMounted() {
        this.update()
    },

    validateForm(form) {
        form.classList.add('was-validated')
        return form.checkValidity()
    },

    // Save a new hour record
    submit() {
        let employeeid = this.props.employeeid
        let callback = this.props.callback
        let errortag = document.getElementById('reportinghours-error')
        let validateForm = this.validateForm

        var form = document.getElementById('reporthours-form');
        let dateField = document.getElementById('reporthours-date')
        let descriptionField = document.getElementById('reporthours-description')
        let amountField = document.getElementById('reporthours-amount')
        return function(e) { 
            errortag.style.display = "none"
            e.preventDefault()
            // Validate form
            if (!validateForm(form)) {
                return
            }
            // let date = dateField.value
            // let description = descriptionField.value
            // let amount = amountField.value
            fetch('/api/reporting', {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "EmployeeID":    employeeid,
                    "Date":          dateField.value,
                    "Description":   descriptionField.value,
                    "Amount":        parseFloat(amountField.value.replace(",", ".")),
                    "ReportingType": 1 // 1=hoursreport
                })
            })
            .then( response => {
                // if response is ok, then call the callback function
                // otherwise an errormessage is a text in the body
                // so display it in the errortag
                if (response.ok) { 
                    dateField.value = ""
                    descriptionField.value = ""
                    amountField.value = ""
                    form.classList.remove('was-validated')
                    callback()
                    return null
                } else {
                    return response.text()
                }
            })
            .then( text => {
                if (text) {
                    throw Error(text)
                }
            })
            .catch( error =>  {
                errortag.style.display = "block"
                errortag.innerHTML = error
                console.error("reporthours.submit:", error)
            })
        }
    },

    // formatDate(date) {
    //     return moment(date).format('L')
    // },
    // // Format number nicely with thousand separators
    // numberWithSeparator(x) {
    //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    // },
    deleteReporting(reportid) {
        let callback = this.props.callback
        let errortag = document.getElementById('reportinghours-error')
        return function(e) {
            errortag.style.display = "none"
            e.preventDefault()
            let url = '/api/reporting/'+reportid
            fetch(url, {
                method:'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then( response => {
                // if response is ok, then call the callback function
                // otherwise an errormessage is sent as a text in the body
                // so display it in the errortag
                if (response.ok) { 
                    callback()
                    return null
                } else {
                    return response.text()
                }
            })
            .then( text => {
                if (text) {
                    throw Error(text)
                }
            })
            .catch((error) =>  {
                errortag.style.display = "block"
                errortag.innerHTML = error
                console.error("reporthours.deleteReporting:", error)
            })
        }
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="card"><div class="card-header">Timeregistrering</div><div class="card-body"><form id="reporthours-form" class="needs-validation" novalidate><table class="table table-sm"><thead><tr><th>Dato</th><th>Formål</th><th>Timer</th><th>Godkendt</th><th></th></tr></thead><tbody><tr expr60="expr60"></tr><tr><td><div class="form-group"><input type="date" class="form-control" id="reporthours-date" name="date" required/><div class="valid-feedback">Valid.</div><div class="invalid-feedback">Der skal vælges en dato.</div></div></td><td><div class="form-group"><input type="text" class="form-control" id="reporthours-description" name="description" placeholder="Beskriv den udførte aktivitet" required/><div class="valid-feedback">Valid.</div><div class="invalid-feedback">Der skal indtastes et formål.</div></div></td><td><div class="form-group"><input type="text" class="form-control" id="reporthours-amount" name="amount" placeholder="Timetal" required/><div class="valid-feedback">Valid.</div><div class="invalid-feedback">Der skal indtastes et timetal.</div></div></td><td><button expr67="expr67" class="btn btn-sm btn-outline-info float-left">Tilføj</button></td></tr></tbody></table></form><div id="reportinghours-error" class="alert alert-danger" role="alert"></div></div></div>',
      [{
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(
          '<td expr61="expr61"> </td><td expr62="expr62"> </td><td expr63="expr63"> </td><td expr64="expr64"></td><td expr65="expr65"></td><td><i expr66="expr66" class="far fa-trash-alt" style="font-size:20px;color:red;cursor:pointer"></i></td>',
          [{
            'redundantAttribute': 'expr61',
            'selector': '[expr61]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.utils.formatDate(scope.report.Date);
              }
            }]
          }, {
            'redundantAttribute': 'expr62',
            'selector': '[expr62]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.report.Description;
              }
            }]
          }, {
            'redundantAttribute': 'expr63',
            'selector': '[expr63]',

            'expressions': [{
              'type': expressionTypes.TEXT,
              'childNodeIndex': 0,

              'evaluate': function(scope) {
                return scope.report.Amount.toFixed(1);
              }
            }]
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return scope.report.IsApproved;
            },

            'redundantAttribute': 'expr64',
            'selector': '[expr64]',
            'template': template('<i class="fas fa-check"></i>', [])
          }, {
            'type': bindingTypes.IF,

            'evaluate': function(scope) {
              return !scope.report.IsApproved;
            },

            'redundantAttribute': 'expr65',
            'selector': '[expr65]',
            'template': template(null, [])
          }, {
            'redundantAttribute': 'expr66',
            'selector': '[expr66]',

            'expressions': [{
              'type': expressionTypes.EVENT,
              'name': 'onclick',

              'evaluate': function(scope) {
                return scope.deleteReporting(scope.report.Id);
              }
            }]
          }]
        ),

        'redundantAttribute': 'expr60',
        'selector': '[expr60]',
        'itemName': 'report',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.props.reportinghours;
        }
      }, {
        'redundantAttribute': 'expr67',
        'selector': '[expr67]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onclick',

          'evaluate': function(scope) {
            return scope.submit();
          }
        }]
      }]
    );
  },

  'name': 'reporthours'
};