import * as riot from 'riot'

import { Router, Route } from '@riotjs/route'
riot.register('router',Router)
riot.register('route',Route)

// Import and register Custom tags
import App from './app.riot'
import Frontpage from './pages/frontpage.riot'
import Login from './pages/login.riot'
import Users from './pages/users.riot'
import Statistics from './pages/statistics.riot'
import User from './pages/user.riot'
import Reporting from './pages/reporting.riot'
import Approve from './pages/approve.riot'
import Selectperiod from '/components/selectperiod.riot'
import FixedFeeEmployees from './pages/fixedfeeemployees.riot'


riot.register('app', App)
riot.register('frontpage', Frontpage)
riot.register('login', Login)
riot.register('users', Users)
riot.register('statistics', Statistics)
riot.register('user', User)
riot.register('reporting', Reporting)
riot.register('approve', Approve)
riot.register('selectperiod', Selectperiod)
riot.register('fixedfeeemployees', FixedFeeEmployees)

// Run App
riot.mount('App')
