export default {
  'css': `selectrole .form-inline .form-group select,[is="selectrole"] .form-inline .form-group select,selectrole .form-inline .form-group label,[is="selectrole"] .form-inline .form-group label,selectrole .form-inline .form-group button,[is="selectrole"] .form-inline .form-group button{ margin-right:10px; }`,

  'exports': {
    onBeforeMount() {
        this.state = {
            roles: {},
        }
        this.getRoles()
    },

    onMounted() {
        this.update()
    },

    getRoles() {
        let url = '/api/roles'
        fetch(url, {
            method:'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then((response) => {
            if (response.status != 200) {
                return Promise.reject(response)
            }
            response.json().then((jsonResponse) => {
                this.assignRoles(jsonResponse)
            })
        })
        .catch( error => {
            console.log("getRoles fetch Error: ", error)
            if (error.status == 401) {
                let errorField = document.getElementById('error')
                errorField.innerHTML = "Der skete en fejl ved hentning af roller: "+error.responseText
                window.location = "/login"
            }
        })
    },

    assignRoles(roles) {
        this.state.roles = roles
        this.update()
    },

    // Called When an role is selected
    selectRole(e) {
        for (var i=0; i < this.state.roles.length; i++) {
            if (this.state.roles[i].ID == e.target.value) {
                break
            }
        }
        this.update()
        this.props.callback(this.state.roles[i])
    }
  },

  'template': function(template, expressionTypes, bindingTypes, getComponent) {
    return template(
      '<div class="form-group"><label class="control-label" for="roleSelection">Vælg Rolle </label><select expr55="expr55" class="form-control" id="roleSelection"><option expr56="expr56"></option></select></div>',
      [{
        'redundantAttribute': 'expr55',
        'selector': '[expr55]',

        'expressions': [{
          'type': expressionTypes.EVENT,
          'name': 'onchange',

          'evaluate': function(scope) {
            return scope.selectRole;
          }
        }]
      }, {
        'type': bindingTypes.EACH,
        'getKey': null,
        'condition': null,

        'template': template(' ', [{
          'expressions': [{
            'type': expressionTypes.TEXT,
            'childNodeIndex': 0,

            'evaluate': function(scope) {
              return scope.role.Name;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'value',

            'evaluate': function(scope) {
              return scope.role.ID;
            }
          }, {
            'type': expressionTypes.ATTRIBUTE,
            'name': 'selected',

            'evaluate': function(scope) {
              return scope.role.ID == scope.props.selectedid;
            }
          }]
        }]),

        'redundantAttribute': 'expr56',
        'selector': '[expr56]',
        'itemName': 'role',
        'indexName': null,

        'evaluate': function(scope) {
          return scope.state.roles;
        }
      }]
    );
  },

  'name': 'selectrole'
};